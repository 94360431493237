import actions, { useActions } from "../../../redux/actions";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import { getMultiLangProperty } from "../../../library/helpers/generalHelpers";
import { useTranslation } from "react-i18next";

export default function PaymentNoticeBar() {
  const { search } = useLocation();
  const { i18n } = useTranslation();
  const query = new URLSearchParams(search);
  const invoiceID = query.get("inv_id");

  const [notice, setNotice] = useState({});

  const InvoicesActions = useActions(actions.InvoicesActions);

  const isValid = useMemo(() => {
    if (!isEmpty(notice) && notice?.status === "active") {
      return notice.to
        ? moment().isBetween(
            moment(notice.from),
            moment(notice.to),
            "day",
            "[]"
          )
        : moment().isSameOrAfter(moment(notice.from), "day");
    }

    return false;
  }, [notice]);

  useEffect(() => {
    if (invoiceID) {
      InvoicesActions.getOne(invoiceID).then((response) => {
        const {
          order_id: { space: { carpark_id: carpark = {} } = {} } = {},
        } = response;

        const { paymentLinkNotice } = carpark || {};
        setNotice(paymentLinkNotice);
      });
    }
  }, [invoiceID]);

  return (
    <div
      style={{
        padding: "10px 20px 0px 20px",
        display: isValid ? "block" : "none",
      }}
      dangerouslySetInnerHTML={{
        __html: isValid
          ? getMultiLangProperty(notice, "title", i18n.language)
          : "",
      }}
    />
  );
}
