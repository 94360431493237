export default {
  learnMore: "了解更多",
  signIn: "登入",
  signUp: "註冊",
  logout: "登出",
  noResults: "沒有結果",
  hkd: "港幣",
  aboutUs: {
    awards: "獎項",
  },
  faq: {
    parking: "泊車查詢",
    general: "一般查詢",
    otherQuestions: "其他問題？",
    contactUs: "聯絡我們",
    details: "點擊查看更多",
  },
  navbar: {
    listing: "尋找車場",
    service: "服務",
    news: "最新消息",
    about: "關於我們",
    account: "我的帳戶",
    profile: "訂單紀錄",
    home: "主頁",
    share: "分享到 {{name}}",
    awards: "獎項",
    faq: "常見問題",
    how: "怎樣運作?",
  },
  footer: {
    reserved: "© {{year}} {{name}} 版權所有",
    version: "版本",
    helpful: "有用連結",
    news: "最新消息",
    contact: "聯絡我們",
    lpTac: "泊車易條款及細則",
    carpark: "尋找車場",
    tac: "泊車條款及細則",
    service: "服務",
    privacy: "私隱政策",
    latest: "最新消息",
    disclaimer: "免責條款",
    about: "關於我們",
    cancellation: "取消政策",
    faq: "常見問題",
    address: "聯絡地址",
    email: "電郵地址",
    phone: "電話號碼",
    fax: "傳真號碼",
    awards: "獎項",
  },
  auth: {
    signIn: {
      welcome: "歡迎回來",
      pleaseLogin: "請登入你的帳戶",
      loginWith: "或以以下方式登入",
      notMember: "尚未登記成為會員？ ",
      signUp: "立即登記！",
      email: "電郵地址",
      password: "密碼",
      remember: "記住登入資料",
      required: "此欄必填",
      invalidEmail: "請填寫有效的電郵地址",
      forget: "忘記密碼？",
      login: "登入",
      phone: "手機號碼",
      invalidCredential: "電郵或密碼無效",
      userNotFound: "用戶不存在",
      notValidated: "請先驗證你的郵箱",
      disabled:
        "帳戶已被鎖或未完成啟動程序, 請先點擊我們已發給你的電郵中的啟動連結。",
      remark1: "閣下登入即代表同意接納本網站之網站 ",
      termsOfUse: "使用條款",
      remark2: " 和接收電郵，以及確認您已閱讀並理解本網站的 ",
      privacyPolicy: "私穩政策",
      accountDisabled:
        "帳戶已被鎖或未完成啟動程序, 請先點擊我們已發給你的電郵中的啟動連結。",
    },
    signUp: {
      activate: "啟用",
      correctInfo: "我確認以上資料均正確無誤",
      correctInfoValidate: "請確認填寫的資料正確無誤",
      mustAgreeTnC: "您必需同意條款及細則才能啟用帳戶",
      next: "下一步",
      welcome: "歡迎",
      createAccount: "建立帳戶",
      haveAccount: "已有帳戶?",
      signUpHere: "請在此登入",
      individual: "個人",
      corporate: "企業",
      firstName: "名字",
      lastName: "姓氏",
      company: " 公司",
      jobTitle: "職位",
      title: "稱謂",
      mr: "先生",
      mrs: "女士",
      ms: "小姐",
      email: "電郵地址",
      emailInvalid: "電郵地址無效",
      mobileInvalid: "電話號碼無效",
      required: "請填上資料",
      password: "密碼",
      passwordRules: "密碼必須有8個或以上的字元，並包含大寫和小寫字母",
      passwordMatch: "密碼必須一樣",
      confirmPassword: "再次輸入密碼",
      mobile: "手提電話號碼",
      whiteSpace: ".",
      verificationCode: "認證號碼",
      sendSms: "發送訊息",
      codeCountDown: "傳送認證碼 {{counter}} 秒",
      agreeMarketing: "我同意將以上的個人資料用作直接促銷活動",
      register: "註冊",
      facebook: "透過 Facebook 登入",
      gmail: "透過 Gmail 登入",
      withEmail: "透過電郵登入",
      smsSent: "短信已經發送至您的手機",
      checkNetwork: "請檢查你的流動網絡設定",
      emailUsed: "電郵地址已經存在!",
      phoneNumberUsed: "手提電話號碼已經存在!",
      smsCodeInvalid: "認證號碼無效",
      congrats: "恭喜！",
      accountCreated: "您已經成功建立帳戶",
      checkEmail: "請查閱您的已登記的電郵並啟動帳戶",
      toHomePage: "前往主頁",
      submitCompanyBr: "請提交公司的商業登記證明至 ",
      forApproval: " 以作批核。",
      informViaEmail: "當批核通過後，我們將會以電郵通知。",
    },
    forgetPassword: {
      reset: "重設密碼",
      enterEmail: "請輸入您的電郵地址，我們會傳送連結給您重設密碼",
      send: "發送電郵",
      emailNotExist: "電郵地址不存在",
    },
    checkMailbox: {
      check: "請檢查您的電郵",
      resetLink: "請前往您的電郵信箱，以取得重設密碼的連結，並設定新的密碼",
      return: "返回登入",
    },
    resetPassword: {
      title: "請選擇新密碼",
      remark: "您請求重設以下帳戶的密碼: {{email}}",
      password: "密碼",
      confirmPassword: "確認密碼",
      save: "儲存新密碼",
      invalidFormat: "密碼必須有8個或以上的字元，並包含大寫和小寫字母",
      notMatch: "兩次密碼不一致",
      error: "重設密碼失敗，請重新嘗試。",
    },
    confirmChangePassword: {
      title: "恭喜！",
      titleInfo: "您的密碼成功更改！",
      remark: "因網絡安全關係，我們將會傳送電郵至您的郵箱，以確定是次更改",
      return: "前往主頁",
    },
    mobileLogin: {
      countryCode: "區號",
      mobileNumber: "手提電話號碼",
      verificationCode: "認證號碼",
      pressToSend: "傳送認證碼",
      resend: "{{number}} 秒後重新發送認證碼",
      send: "發送訊息",
      invalidPhone: "手提電話號碼無效",
      smsHasSent: "短信已經發送至您的手機",
      checkNetwork: "請檢查你的流動網絡設定",
      invalidCode: "認證號碼無效",
      invalidInput: "輸入無效",
      email: "電郵",
    },
  },
  home: {
    searchArea: {
      search: "搜索",
      monthly: "月租",
      hourly: "時租",
      carpark: "車場",
      from: "由",
      to: "至",
      anyLocations: "任何位置",
      poweredBy: "技術提供",
    },
    news: {
      continue: "閱讀更多...",
    },
    contactUs: {
      heading: "關於我們",
      readMore: "了解更多",
      title: "聯絡我們",
      subTitle:
        "如果您對我們的服務有任何疑問，請填妥以下表格，我們將盡快與您聯絡。",
      name: "姓名",
      email: "電郵地址",
      subscription: "訂閱電郵",
      message: "訊息",
      submit: "提交",
      required: "請填寫此欄！",
      validEmail: "請輸入正確的電郵地址",
      validPhone: "請輸入正確的手機號碼",
      validCarplate: "請輸入正確的車牌號碼",
      submitted: "提交成功",
      phone: "手機號碼",
      carplate: "車牌號碼",
      subject: "主旨",
      district: "地區",
      vehicleType: "車輛類型",
      GeneralEnquiry: "一般查詢",
      ServiceEnquiry: "服務查詢",
      all: "全部 / 任何",
    },
  },
  listing: {
    notApplicable: "不適用",
    filter: "篩選",
    result: "{{total}} 個搜尋結果",
    hour: "小時",
    month: "月",
    walkin: "",
    startDate: "由",
    endDate: "至",
    price: "港幣",
    location: "任何位置",
    showMap: "顯示地圖",
    vehicle: "車輛類型",
    service: "服務",
    feature: "特點",
    direction: "顯示路線",
    indoor: "室內",
    withCover: "有蓋",
    noCover: "無蓋",
    noMatch: "沒有符合你要求的停車場",
    tryOther: "請嘗試以其他條件搜尋",
    residentOnly: "僅供住戶租用",
  },
  carparkDetail: {
    overview: "概述",
    location: "相片/位置",
    operatingTime: "24小時營業",
    monToFri: "星期一至星期五",
    saturday: "星期六",
    sunAndPH: "星期日及公眾假期",
    monFri: "星期一至星期五",
    sat: "星期六",
    sunPH: "星期日及公眾假期",
    closed: "關閉",
    carparkSuitable: "車位適合",
    availableServices: "服務類別",
    otherDetails: "其他詳情",
    headroom: "車場高度",
    restrictions: "使用限制",
    accessType: "進出途徑",
    paymentMethod: "付款方式",
    remarks: "備註",
    noRestriction: "沒有限制",
    ownersOnly: "大廈業主使用",
    parkers: "大廈業主及車主使用",
    visitorsOnly: "訪客使用",
    accessCard: "智能卡 / 裝置",
    octopusCard: "八達通卡",
    accessTypeNo: "無須智能卡 / 裝置",
    mAbove: "{{height}}米以上",
    noLimit: "沒有限制",
    m: "{{height}}米",
    map: "地圖",
    streetView: "街景",
    photos: "相片",
    packageOptions: "收費選項",
    parkingRates: "收費詳情",
    bookNow: "即時網上預訂",
    indoor: "室內",
    noCovers: "無蓋",
    withCovers: "有蓋",
    ev: "電動車充電設施",
    perHour: "每小時",
    perMonth: "每月",
    bookingAvailable: "可接受預約",
    full: "滿額",
    select: "選取",
    selected: "已選",
    from: "由",
    minBooking: "* 最少預訂時間： {{hour}} 小時",
    ph: "公眾假期",
    packageDetails: "收費詳情",
    rentalFee: "今個月的費用",
    nextRentalFee: "下個月的費用",
    parkingFee: "停車場費用",
    total: "總計",
    instant: "即時預約",
    instantRemark: "你可即時預約且不需審批",
    to: "至",
    selectTime: "請選擇泊車時間",
    minBookingAlert: "最少預訂 {{hour}} 小時!",
    maxBookingAlert: "最長預定期為兩個月!",
    endDateAlert: "此結束日期不允許選擇!",
    noResults: "沒有符合你要求的停車場",
    tryDiffKeyword: "請嘗試以其他條件搜尋",
    apply: "申請",
    proceed: "繼續",
    notice: "通知",
    noticeRemark:
      "此停車位僅供住戶租用，請提供所需之證明文件以繼續預訂。請參閱下列遞交文件之步驟。",
    residentOnly: "僅供住戶租用",
    tncTitle: "個人資料收集聲明",
    tnc:
      "閣下所提供的個人及其他有關資料會用於處理日常停車場之服務(包括處理及跟進查詢、及提供詳細停車場資料)。閣下所提供的資料會有機會提供予：新鴻基地產(銷售及租貸)代理有限公司、附屬公司、聯營公司或關聯公司、或本公司控制或共同控制的公司、停車場之業主或其管理代理，或任何向新鴻基地產(銷售及租貸)代理有限公司提供停車場業務相關的服務的代理、承辦商或第三方服務供應商。閣下所提供的個人資料之保存時間不會超過個人資料(私隱)條例所要求，並將會妥善銷毀。閣下有權要求索閱我們所持有有關閣下之個人資料。任何有關索閱、修改個人資料的要求，或對本聲明之內容有任何疑問，可以致電到 {{phone}} 聯絡新鴻基地產(銷售及租貸)代理有限公司或電郵到 {{email}}。</br></br>" +
      "中文譯本僅供參考，文義如與英文版本有歧異，一概以英文版本為準。",
    tncRemark: "請先細閱下列個人資料收集聲明。",
    agreeAndProceed: "同意並繼續",
    reserve: "預留車位",
    reserveQuotaRemark:
      "你的申請正在處理中，你可以先預留此停車位，職員會盡快聯絡閣下已作出安排。",
    reserved: "已預留",
    alreadyReserved: "已有預留中的車位",
    alreadyReservedRemark:
      "你在此停車場已有預留中的車位，如預留新車位表示閣下放棄現有預留的車位。",
  },
  payment: {
    vehicleRegistration: "註冊車輛",
    pleaseSelect: "請選擇...",
    addVehicle: "新增車輛",
    vehicleType: "車輛類型",
    registrationMark: "車牌號碼",
    cancel: "取消",
    accessMethod: "進出車場方式",
    addOctCard: "新增八達通",
    octCardRemark1: "您只可使用此已登記的八達通卡進出指定車場。",
    octCardRemark2: "請於泊車當日攜帶同一張八達通卡。",
    octCardRemark3: "卡背面右下角八達通號碼除括號外的所有數字",
    octCard: "八達通",
    paymentMethod: "付款方式",
    selectPayment: "請選擇付款方式",
    fps: "轉數快",
    eleven: "7-11便利店",
    circleK: "OK便利店",
    cheque: "匯豐入票易",
    otherPayment: "其他付款方式",
    onlinePayment: "信用卡",
    useOtherCreditCard: "使用其他信用卡",
    enter: "輸入資料:",
    cardNumber: "信用卡號碼",
    cardHolder: "持卡人姓名",
    cvc: "安全碼",
    mm: "月",
    yy: "年",
    addCreditCard: "在您的帳戶加入此信用卡節省下次預約時間。",
    letsParkTAC: "泊車易條款及細則",
    parkingTAC: "物業條款及細則",
    privacyPolicy: "私隱政策",
    disclaimer: "免責條款",
    cancellationPolicy: "取消政策",
    readAndAgree: "我已閱讀及同意",
    and: "以及",
    agreeToPay:
      "，並同意支付以上款項。(從服務到期前的3天起無法使用7-11便利店及匯豐入票易付款)",
    tncRemark: "{{company}}授權泊車易(香港)有限公司收取所有網上繳交之款項。",
    next: "下一步",
    bestView: "以最新版本的Google Chrome及Safari瀏覽器閱讀本網頁效果最佳。",
    cardRemoved: "信用卡移除成功",
    invalidCardInfo: "信用卡信息不正確",
    selectVehicle: "請選擇你的車牌號碼",
    servicePackage: "服務類型",
    servicePeriod: "服務時段",
    promotionCode: "優惠碼",
    invalidCode: "請輸入正確優惠碼",
    currentRentalFee: "今個月的費用",
    nextRentalFee: "下個月的費用",
    discount: "優惠",
    total: "總計",
    paymentSummary: "付款詳情",
    promotionError1: "請輸入正確優惠碼",
    promotionError2: "您曾使用過此優惠碼一次",
    promotionError3: "此優惠碼並不適用所選用戶",
    promotionError4: "此優惠碼並不適用所選車輛",
    promotionError5: "此優惠碼並不適用所選預訂車位",
    promotionError6: "此優惠碼並不適用所選預訂停車場",
    promotionError7: "此優惠碼無效或已過期",
    promotionError8: "此優惠碼已超過可使用的次數",
    promotionError9:
      "折扣後的泊車費用必須大於HK$0。如有疑問，請聯絡我們的客戶團隊",
    chooseCarType: "請選擇車輛類型",
    enterCarPlate: "請輸入車牌號碼",
    enterOctCardNumber: "請輸入八達通號碼",
    numberOnly: "八達通卡序號無效（只限數字）",
    correctFormat: "請輸入八達通號碼除括號外的所有數字",
    selectOctCard: "請選擇八達通卡",
    enterName: "請輸入持卡人姓名",
    invalidCreditCardNumber: "信用卡號碼無效",
    invalidCVC: "安全碼無效",
    invalidMonth: "月份不正確",
    invalidYear: "年份不正確",
    wrongCardInfo: "信用卡資料錯誤，請稍候再試。",
    selectCard: "請選擇信用卡",
    duplicateService: "相同車牌已用於選擇的服務",
    duplicateCarPark: "相同車牌已用於此停車場",
    noDateSetting: "停車場設定錯誤，請聯絡我們",
    duplicateRenewal:
      '你已提交續訂服務，請使用已發送之連結付款，或到 "個人帳戶 > 發票" 重新取得付款連結',
    invalidMethod: "付款方法並不適用於此停車場，請選擇其他付款方法",
    serverError: "系統錯誤，請稍候再試。",
    selectMethod: "請選擇付款方式",
    acceptTAC: "請接受條款及細則",
    pleaseLogin: "請先登入你的帳戶",
    reservation: "預留車位",
    reserve: "預留車位",
    reserveQuotaRemark:
      "由於你的申請正在處理中，而你所選的停車位僅供住戶使用，你可以先預留此停車位，職員會盡快與閣下聯絡以作相關安排。",
    collectMethod: "選擇領取方法",
    parkingCard: "月租咭",
    selectCollectionMethod: "請選擇領取月租咭方式",
    parkingCardCollect: {
      mso_office: "物業管理處",
      mail: "速遞",
      self_pickup: "自行領取",
    },
    invoiceCancelled: "發票已被取消。",
  },
  oneTimePayment: {
    tempChange: "更改車牌 - 臨時",
    permChange: "更改車牌 - 永久",
    paymentSummary: "付款詳情",
    servicePackage: "服務類型",
    servicePeriod: "服務時段",
    invoiceID: "發票編號",
    issueDate: "簽發日期",
    dueDate: "截數日期",
    customerName: "客戶名稱",
    email: "電郵地址",
    tel: "電話號碼",
    vehicle: "單位號碼",
    newPlate: "新的車牌號碼",
    total: "總計",
    invoiceConfirmed:
      '本發票的付款已獲確認，您可以在"帳戶 > 發票"中查看您的付款紀錄。',
    invoicePending:
      "我們正在處理您早前提交的付款請求。您可以在此頁重新選擇付款方法，但您早前的發票將會被取消。",
    invoiceCancelled: "發票已被取消。",
    paymentLinkInvalid: "此連結或已無效，請再試一次。",
    WILMemberReminder:
      "簡單一步免費登記，即可做WIL會員，每次月租都可賺分；更尊享額外2小時免費泊車及港幣$100加德士汽油優惠券！",
    regNow: "立即登記",
    skip: "跳過",
    showAgain: "不再顯示",
  },
  payment711: {
    remark: "備註",
    paymentInfo: "付款資料",
    scanReceipt: "掃描收據",
    remark1:
      "此二維碼只在24小時內生效，請盡快處理付款。在時限過期後，您須重新選擇付款方式以獲取新的二維碼。",
    remark2: "付款後請上載您的有效收據以便進行 OCR 驗證辨識。",
    remark3: "請確保上載有效正確的收據以進行驗證。",
    remark4: "上傳收據後，選擇 [確認收據]。",
    remark5:
      "請在月租到期前最少 {{paymentBuffer}} 天前完成付款及上載收據作驗證。",
    payAt: "7-11 便利店付款",
    showQrCode: "請向店員出示二維碼付款",
    refresh: "(請在返回此頁時刷新)",
    incorrectMonth: "如顯示月份不正確，請關閉並刷新瀏覽器",
    servicePackage: "服務類型: ",
    servicePeriod: "泊車時間: ",
    vehicleRegMark: "車牌號碼: ",
    uploadAfterPayment: "請於付款後上載收據",
    limit: "上限: ",
    uploadReceipt: "上載收據",
    backHome: "返回主頁",
    uploadFail:
      "很抱歉！系統未能成功上傳您的收據，請確認照片大小少於 5 MB並重新上載您的收據。",
    scanFail0: "很抱歉！系統不能讀取您的收據，請重新上載您的收據。",
    scanFail1:
      "1. 收據拍攝必須清晰可見，其中收據金額，戶口號碼和收據付款日期必須包含照片中。",
    scanFail2: "2. 在光線充足的地方拍攝收據。",
    scanFail3: "3. 關閉閃光燈以避免過份曝光。",
    scanFail4: "4. 放在平坦的表面上拍攝收據。",
    exceedLimit:
      "您已上載收據和進行OCR驗證辨識3次不成功。請聯繫我們的客服團隊，以作進一步協助。",
  },
  paymentReceipt: {
    content: "收據內容",
    billNumber: "戶口號碼",
    amount: "收據金額",
    date: "收據日期",
    time: "收據時間",
    remark: "請確保其收據資料正確及與付款頁面內容相符。",
    uploadAgain: "重新上載",
    confirmAmount: "確認收據金額",
    confirm: "確認收據",
  },
  paymentFPS: {
    payBy: "轉數快付款",
    remark0: "選擇轉數快後,",
    remark1Mobile: "請用螢幕截圖儲存二維碼。",
    remark2Mobile: "用手機開啟閣下指定銀行之應用程式。",
    remark3Mobile: "選擇「轉數快付款」，並登入銀行帳戶。",
    remark4Mobile: "上載已儲存的二維碼後繼續付款。",
    remark1Desktop: "一個二維碼會顯示於電腦螢幕上。",
    remark2Desktop: "用手機開啟閣下指定銀行之應用程式。",
    remark3Desktop: "選擇「轉數快付款」，並登入銀行帳戶。",
    remark4Desktop: "以手機掃描於電腦螢幕上的二維碼，然後繼續付款。",
    scanCode: "請開啟閣下指定銀行之手機應用程式，掃描於電腦螢幕上的二維碼",
    completePayment: "完成付款",
  },
  paymentCQM: {
    payBy: "於匯豐銀行「入票易」以支票入賬",
    checkRemark: "支票抬頭請寫「泊車易(香港)有限公司」",
    billingType: "賬單類別: ",
    billAccountNumber: "賬單戶口號碼:",
    uploadAfterPayment: "請於入票後上載收據",
    completePayment: "完成付款",
    remark1:
      "選擇「入票易」。一個18位數字的賬單戶口號碼會以短訊及電郵型式傳送給您。",
    remark2:
      "預備劃線支票，收款人為「泊車易(香港)有限公司」。前往設有「入票易存票機」的匯豐銀行。",
    remark3:
      "於「入票易」: 選擇 [繳付賬單] > [其他機構] > [泊車易(香港)有限公司] > [01月租費用]。然後輸入支票金額及18位數字賬單戶口號碼。",
    remark4: "插入支票並保留通知書。",
    remark5: "按「相機標誌」，上載收據以確認付款。",
    payofMonthly: "月租費用",
    payofother: "其他費用",
  },
  paymentSuccess: {
    title: "成功付款",
    invoice: "你的發票編號是",
    smsRemark: "付款一經確認，系統會發出電郵/短訊通知。",
    arrangeRemark: "請聯絡停車場職員以安排預留停車位的選擇。",
    remark: "你可查閱預約詳情及管理相關帳戶",
    back: "返回主頁",
  },
  paymentError: {
    title: "發生錯誤",
    remark: "請稍後再試",
    search: "重新搜尋",
  },
  paymentExpired: {
    title: "此連結或已無效",
    remark1: "付款連結會在發票取消或過期後失效。",
    remark2: "如你的連結尚未失效，請關閉所有測試停車場 網頁後再試。",
    remark3: "你可查閱預約詳情及管理相關帳戶",
    profile: "前往基本資料",
  },
  paymentPendingSMS: {
    title: "請確認你的手機號碼",
    phone: "付款詳情已傳送至此手提電話號碼",
    remark: "請點擊傳送至此號碼的連結，以完成付款程序。",
    send: "再次發送訊息",
    sendAgain: "[{{sec}}秒後可再次傳送]",
    back: "返回主頁",
    sent: "連結已重新發送。",
  },
  paymentProcessing: {
    title: "付款進行中",
    invoice: "你的發票編號是 {{id}}",
    smsRemark: "付款一經確認，系統會發出電郵/短訊通知。",
    remark: "你可查閱預約詳情及管理相關帳戶",
    back: "返回主頁",
  },
  reserveSuccess: {
    title: "成功預留",
    invoice: "你的發票編號是",
    remark: "申請一經確認，系統會發出付款連結。",
    back: "返回主頁",
  },
  accountSettings: {
    basic: "基本資料",
    firstName: "姓名",
    lastName: "姓氏",
    title: "稱謂",
    lang: "語言設置",
    company: "公司",
    jobTitle: "職位",
    email: "電郵地址",
    countryCode: "區號",
    phone: "手提電話號碼",
    code: "認證碼",
    send: "傳送",
    address: "聯絡地址",
    room: "單位編號",
    floor: "樓層",
    block: "座號",
    building: "屋宇大廈名稱",
    street: "街道名稱/號數",
    district: "地區",
    save: "儲存更改",
    required: "此欄必填",
    invalidEmail: "電郵地址無效",
    emailExist: "電郵地址已被使用",
    invalidPhone: "手提電話號碼無效",
    phoneExist: "手提電話號碼已被使用",
    invalidCode: "認證碼無效",
    success: "更新成功",
    mr: "先生",
    ms: "小姐",
    mrs: "女士",
    en: "英文",
    tc: "繁體中文",
    fail: "更新失敗。請重試。",
    viewProfile: "查看訂單紀錄",
    editProfile: "編緝個人資料",
    changePhoto: "更改個人頭像",
    editVehicle: "編緝車輛列表",
    profileImage: "個人頭像",
    upload: "上載圖片",
    exceedLimit: "圖片不能大於 1 MB",
    uploadFail: "未能上載圖片。請重新嘗試。",
    vehicles: "我的車輛",
    carPlate: "車牌號碼",
    carType: "車輛類別",
    default: "預設",
    setDefault: "設為預設",
    uploading: "上載中...",
  },
  profile: {
    service: "服務",
    invoice: "發票",
    payNow: "支付",
    pay: "支付",
    cancel: "取消",
    check: "查看",
    carparkName: "停車場名稱",
    servicePackage: "服務類型",
    vehicle: "車牌號碼",
    status: "狀況",
    date: "日期",
    total: "總計",
    orderID: "訂單編號",
    invoiceID: "發票編號",
    paymentCancelled: "支付已取消",
    tempVehicleChange: "更改車牌 - 臨時",
    permVehicleChange: "更改車牌 - 永久",
    monthlyFee: "月租服務",
    hourlyFee: "時租服務",
    confirmed: "有效",
    pendingRenew: "待續訂",
    pendingPayment: "待付款",
    pendingProcess: "處理中",
    cancelled: "已取消",
    expired: "已過期",
    terminated: "已終止",
    error: "錯誤",
    empty: "沒有紀錄",
    sortDescend: "降序排列",
    sortAscend: "順序排列",
    endDate: "服務有效日期",
    monthly: "月租",
    hourly: "時租",
    renewOn: "續訂於",
    configuration: "設定",
    merchant: "商戶名稱",
    displayMore: "顯示更多",
    renew: "網上續訂",
    vehicleChange: "更改車輛",
    months: "月份",
    type: "類型",
    download: "下載",
    buyNew: "網上預訂",
    bookingPeriod: "訂單時段",
    changeRequestInfo: "更改所需資料",
    temporary: "臨時",
    permanent: "永久",
    from: "由",
    to: "至",
    newPlate: "新的車牌號碼",
    totalPayable: "總銀碼",
    change: "更改",
    effectiveDate: "有效日期",
    alreadyChanged: "抱歉，你本月內已經更改車牌",
    chooseCarType: "請選擇車輛類型",
    enterCarPlate: "請輸入車牌號碼",
    selectNewPlate: "請選擇新的車牌號碼",
    selectVehicle: "請選擇你的車輛",
    vehicleChangeSuccess: "更新車牌成功",
    changeService: "更改服務",
    changeCarPlate: "更改車牌號碼",
    instantRenew: "即時網上續訂",
    contractRenewal: "合約更新",
    month: "月",
    chooseService: "請選擇服務",
    chooseCarpark: "請選擇車場",
  },
  service: {
    title: "服務",
    subTitle: "全面及專業停車場服務",
  },
  news: {
    title: "最新消息",
    readmore: "閱讀更多 ...",
  },
  ocrVerification: {
    title: "OCR 驗證",
    back: "返回",
    next: "下一步",
    heading1: "步驟一: 條款及細則",
    heading2: "步驟一: 上傳文件",
    heading3: "步驟二: 驗證結果",
    agree: "我已細閱及同意以上條款及細則",
    imageReference: "圖片參考",
    uploadIDCard: "上傳身份證",
    uploadAddressProof: "上傳住址證明",
    addressProof: "住址證明",
    clp: "電費單(中華電力)",
    hk: "電費單(香港電燈)",
    water: "水費單",
    towngas: "煤氣單",
    selectAddressProofType: "請先選擇住址證明類別",
    preview: "預覽",
    previewRemark: "備註: 影像的下三分之一位置於上傳前已被覆蓋。",
    previewRemark2: "請將圖像設置為橫向及正面朝上。",
    retry: "重新選擇",
    confirm: "確認",
    residentName: "住戶名稱",
    buildingName: "大廈名稱",
    nameNotMatch: "身份證上顯示的姓名與住址證明不符。",
    invalidBuilding: "上載的住址證明文件與系統登記的紀錄不符。",
    uploadAgain: "請重新上傳",
    stillHaveQuestions: "還遇到問題？",
    message: "留下你的訊息...",
    remark: "備註: 成功驗證後 (自動/人手)，文件將會被移除。",
    successTitle: "成功驗證",
    successContent: "你現在可以預訂車位。",
    failTitle: "驗證失敗",
    failContent:
      "職員將會跟進您的申請並盡快與閣下聯絡，請按「下一步」及先選取您的預訂車位。\n" +
      "系統將保留您的預訂長達 24 小時。",
    hkidMaxRetry:
      "您已3次不成功上載身份證以進行驗證。\n" +
      "請電郵至 {{email}}或致電 {{phone}}，我們的客服團隊將作進一步協助。",
    addressProofMaxRetry:
      "您已3次不成功上載賬單以進行驗證。\n" +
      "請電郵至 {{email}}或致電  {{phone}}，我們的客服團隊將作進一步協助。",
    hkidImage: "身份證",
    addressProofImage: "住址證明",
    notRecognised:
      "很抱歉！系統不能讀取您的收據，請重新上載您的收據。\n\n" +
      "1.收據拍攝必須清晰可見，其中收據金額，戶口號碼和收據付款日期必須包含照片中。\n" +
      "2.在光線充足的地方拍攝收據。\n" +
      "3.關閉閃光燈以避免過份曝光。\n" +
      "4.放在平坦的表面上拍攝收據。",
    updateFailed: "未能更新備註，請重試",
    ocrError: "你的申請正在處理中，請耐心等候職員的回覆。",
    ocrApproved: "你的申請已被批核。",
    ocrCheckStatusFailed: "無法取得你的申請狀態，請重試。",
    rotateLeft: "向左旋轉",
    rotateRight: "向右旋轉",
  },
  maintain: {
    maintainMessage:
      "網站現正進行維修/系統升級。\n\n如造成不便，敬請原諒。我們很快會回來。",
  },
  award: {
    title: "獎項",
  },
};
