import React, { useContext, useState } from "react";
import Sticky from "react-stickynode";
import { withRouter } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { Button, Drawer } from "antd";
import Text from "components/UI/Text/Text";
import TextLink from "components/UI/TextLink/TextLink";
import Navbar from "components/Navbar/Navbar";
import { LayoutContext } from "context/LayoutProvider";
import useWindowSize from "library/hooks/useWindowSize";
import { ACCOUNT_SETTINGS, CAR_PARK_LIST, HOME_PAGE } from "settings/constant";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AuthMenu from "./AuthMenu";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import ProfileMenu from "./ProfileMenu";
import NavbarSearch from "./NavbarSearch";
import HeaderWrapper, {
  AvatarImage,
  AvatarInfo,
  AvatarWrapper,
  CloseDrawer,
  LogoArea,
  MobileNavbar,
} from "./Header.style";
import HomeNavbar from "./HomeNavbar";
import DEFAULT_AVATAR from "../../../assets/images/profile/default-avatar.png";
import MobileAuthMenu from "./MobileAuthMenu";
import Logo from "../../../components/UI/Logo/Logo";

const cookies = new Cookies();

export default withRouter(({ location }) => {
  const { t } = useTranslation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const token = cookies.get("token");
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const { logo, favicon } = useSelector((state) => state.merchant);
  const { avatar, firstName, lastName } = useSelector((state) => state.users);
  const {
    landing: {
      footer: {
        facebook = false,
        google = false,
        wechat = false,
        phone: showPhone = false,
        email: showEmail = false,
        awards = false,
      } = {},
      register: { displaymain: showRegister } = {},
      footercontent: { phone = "", email = "" } = {},
      search: { displaymain: showCarpark } = {},
      services: { displaynav: showService } = {},
      news: { displaynav: showNews } = {},
      about: { displaynav: showAbout } = {},
      awards: { displaynav: showNavAwards, displaymain: showMainAwards } = {},
      faq: { displaynav: showNavFAQ } = {},
      how: { displaynav: showNavHOW } = {},
    } = {},
  } = useSelector((state) => state.site);
  const sidebarHandler = () => {
    setState(!state);
  };

  const headerType = location.pathname === "/" ? "transparent" : "default";

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === "transparent" ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 1300 ? (
          <>
            <HomeNavbar
              logo={logo}
              className={
                !searchVisibility || location.pathname !== HOME_PAGE
                  ? "is_show_transition"
                  : ""
              }
              isLogin={token}
              socialLink={{
                facebook,
                google,
                wechat,
              }}
              phone={showPhone ? phone : false}
              email={showEmail ? email : false}
              showRegister={showRegister}
              showCarpark={showCarpark}
              showService={showService}
              showNews={showNews}
              showAbout={showAbout}
              showAwards={showNavAwards}
              showFAQ={showNavFAQ}
              showHOW={showNavHOW}
            />
            <Navbar
              logo={<Logo logo={logo} />}
              navMenu={
                <MainMenu
                  showCarpark={showCarpark}
                  showService={showService}
                  showNews={showNews}
                  showAbout={showAbout}
                  showAwards={showNavAwards}
                  showFAQ={showNavFAQ}
                  showHOW={showNavHOW}
                />
              }
              authMenu={<AuthMenu showRegister={showRegister} />}
              isLogin={token}
              avatar={<img src={avatar || DEFAULT_AVATAR} />}
              profileMenu={
                <ProfileMenu avatar={<img src={avatar || DEFAULT_AVATAR} />} />
              }
              headerType={headerType}
              searchComponent={
                location.pathname === CAR_PARK_LIST && <NavbarSearch />
              }
              location={location}
              searchVisibility={searchVisibility}
              className={
                !searchVisibility && location.pathname === HOME_PAGE
                  ? "is_show_transition"
                  : ""
              }
            />
          </>
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <Logo
                logo={location.pathname === CAR_PARK_LIST ? favicon : logo}
                width={location.pathname === CAR_PARK_LIST ? 30 : 190}
                maxWidth={location.pathname === CAR_PARK_LIST ? 30 : 190}
              />
              {location.pathname === CAR_PARK_LIST && <NavbarSearch />}
            </LogoArea>
            <Button
              className={`hamburg-btn ${state ? "active" : ""}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              visible={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {token ? (
                <AvatarWrapper>
                  <AvatarImage>
                    <img src={avatar || DEFAULT_AVATAR} />
                  </AvatarImage>
                  <AvatarInfo>
                    <Text as="h3" content={`${lastName} ${firstName}`} />
                    <TextLink
                      link={ACCOUNT_SETTINGS}
                      content={t("navbar.account")}
                      onClick={() => sidebarHandler()}
                    />
                  </AvatarInfo>
                </AvatarWrapper>
              ) : (
                <MobileAuthMenu
                  className="mobile-auth-menu"
                  showRegister={showRegister}
                  onClose={sidebarHandler}
                />
              )}
              <MobileMenu
                className="main-menu"
                showCarpark={showCarpark}
                showService={showService}
                showNews={showNews}
                showAbout={showAbout}
                onClose={sidebarHandler}
              />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
});
