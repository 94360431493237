import { combineReducers } from "redux";
import SiteReducer from "./site/reducer";
import ServiceContentReducer from "./servicecontent/reducer";
import NewsReducer from "./news/reducer";
import CarparksReducer from "./carparks/reducer";
import MerchantReducer from "./merchant/reducer";
import SpacesReducer from "./spaces/reducer";
import VehiclesReducer from "./vehicles/reducer";
import DatesReducer from "./dates/reducer";
import AuthReducer from "./auth/reducer";
import UsersReducer from "./users/reducer";
import AggregatorReducer from "./aggregator/reducer";
import PromotionReducer from "./promotion/reducer";
import ParkingReducer from "./parking/reducer";
import BookingsReducer from "./bookings/reducer";
import InvoicesReducer from "./invoices/reducer";
import TransactionsReducer from "./transactions/reducer";
import UploadReducer from "./upload/reducer";
import OCRReducer from "./ocr/reducer";
import AwardReducer from "./award/reducer";
import WilRegisterReducer from "./wilRegister/reducer";

export default combineReducers({
  site: SiteReducer,
  serviceContent: ServiceContentReducer,
  news: NewsReducer,
  carparks: CarparksReducer,
  merchant: MerchantReducer,
  spaces: SpacesReducer,
  vehicles: VehiclesReducer,
  dates: DatesReducer,
  auth: AuthReducer,
  users: UsersReducer,
  aggregator: AggregatorReducer,
  promotion: PromotionReducer,
  parking: ParkingReducer,
  bookings: BookingsReducer,
  invoices: InvoicesReducer,
  transactions: TransactionsReducer,
  upload: UploadReducer,
  ocr: OCRReducer,
  award: AwardReducer,
  wilRegister: WilRegisterReducer,
});
